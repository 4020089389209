import React from 'react';
import './TermsService.css';

const TermsService = () => {
    return (
        <div className="termsservice">
            <h1>Terms of Service</h1>
            <p><strong>Last Updated: 14/12/2024</strong></p>
            <p>
                Welcome to FRONTIER AI (“we”, “us”, or “our”). By accessing, viewing or otherwise using any portion of 
                our services (collectively, the “Services”), you agree to comply with and be bound by the following 
                Terms of Service ("Terms"). If you do not agree with these Terms, please do not use our Services.
            </p>
            <p>
                These Terms constitute a legally binding agreement between you (“User” or “you”) [CUSTOMER] regarding your 
                use of our Services, including any content, functionality, and features offered through the Services.
            </p>

            <h2>1. Acceptance of Terms</h2>
            <p>
                By using or accessing the Services, you affirm that you are at least 18 years old and that you have the 
                capacity to enter into this agreement. If you are using the Services on behalf of a company or organisation, 
                you represent that you have the authority to bind that entity to these Terms.
            </p>

            <h2>2. Services Overview</h2>
            <p>
                FRONTIER AI provides AI co-workers for healthcare organisations to help them combat workforce pressures 
                and staffing shortages. The AI co-workers are deployed alongside administrative staff members to act as 
                a force multiplier for productivity.
            </p>

            <h2>3. Eligibility</h2>
            <p>
                You may use the Services only if you are legally able to enter into a binding contract, and if your use of 
                the Services is not prohibited by applicable laws, including (without limitation) data protection laws 
                such as the UK/EU General Data Protection Regulation (GDPR) and the Data Protection Act 2018 (DPA).
            </p>

            <h2>4. Account Registration</h2>
            <p>
                To access certain features of the Services, you will be issued with a user account. In the context of your 
                account, you agree to provide accurate, current, and complete information and to update your information 
                as necessary. You are responsible for maintaining the confidentiality of your account information and for 
                all activities that occur under your account.
            </p>

            <h2>5. Use of Services</h2>
            <p>You agree to use the Services only for lawful purposes and in accordance with these Terms. You may not:</p>
            <ul>
                <li>Use the Services for any illegal or unauthorised purpose;</li>
                <li>Distribute harmful content, such as viruses or malware;</li>
                <li>Attempt to gain unauthorised access to our systems or data;</li>
                <li>Reverse engineer, decompile, or otherwise attempt to extract the source code of the Services;</li>
                <li>Use the Services in a way that could damage, disable, or impair the functioning of the Services.</li>
            </ul>

            <h2>6. Privacy and Data Protection</h2>
            <p>
                Your privacy and the security of your data are of paramount importance to us. Please review our{' '}
                <a href="/privacy-notice">Privacy Notice</a> to understand how we collect, use, and protect your personal 
                data. By using the Services, you consent to the collection and use of your data as outlined in the 
                Privacy Notice.
            </p>
            <p><strong>GDPR Compliance:</strong></p>
            <p>
                We comply with the relevant data protection laws and regulations including (without limitation) UK General 
                Data Protection Regulation (UK GDPR) and the Data Protection Act 2018 (DPA). If you are an EU or UK 
                resident or your data is processed in the UK or EU, we will process your personal data in accordance with 
                the relevant laws and regulations.
            </p>
            <p>Our Privacy Notice outlines the details of how we manage personal data, including:</p>
            <ul>
                <li>The types of personal data we collect;</li>
                <li>The legal bases for processing personal data (such as consent, contractual necessity, etc.);</li>
                <li>How long we retain your data;</li>
                <li>
                    Your rights regarding your personal data (including access, rectification, erasure, restriction of 
                    processing, data portability, and objection).
                </li>
            </ul>

            <h2>7. Intellectual Property</h2>
            <p>
                All content, software, algorithms, designs, and other intellectual property provided through the Services, 
                including but not limited to text, graphics, images, and trademarks, are owned by FRONTIER AI or its 
                licensors and are protected by intellectual property laws.
            </p>
            <p>
                You may not use, copy, modify, distribute, or create derivative works based on any part of the Services 
                without our express written permission.
            </p>

            <h2>8. Disclaimers and Limitations of Liability</h2>
            <p>
                <strong>Disclaimer of Warranties:</strong> The Services are provided "as is" and “as available.” We do not 
                make any representations or warranties, express or implied, regarding the accuracy, completeness, or 
                reliability of the Services. We disclaim all warranties, including implied warranties of merchantability 
                or fitness for a particular purpose.
            </p>
            <p>
                <strong>Limitation of Liability:</strong> To the maximum extent permitted by law, FRONTIER AI will not be 
                liable for any indirect, incidental, special, or consequential damages, including loss of profits, data, 
                or business opportunities, arising out of or in connection with your use of the Services.
            </p>

            <h2>9. Indemnification</h2>
            <p>
                You agree to indemnify and hold harmless FRONTIER AI, its affiliates, employees, and agents from and 
                against any claims, damages, losses, or expenses arising out of your use of the Services or your violation 
                of these Terms.
            </p>

            <h2>10. Modification of Terms</h2>
            <p>
                We reserve the right to update or modify these Terms at any time. Any changes will be posted on this page 
                with an updated "Last Updated" date. Your continued use of the Services after such changes constitutes 
                your acceptance of the revised Terms.
            </p>

            <h2>11. Termination</h2>
            <p>
                We may suspend or terminate your access to the Services at our discretion, without notice, for any reason, 
                including but not limited to violations of these Terms. Upon termination, you must cease all use of the 
                Services and delete any downloaded content.
            </p>

            <h2>12. Governing Law and Dispute Resolution</h2>
            <p>
                These Terms will be governed by and construed in accordance with the laws of England and Wales. Any 
                disputes arising out of or in connection with these Terms shall be resolved through binding arbitration or 
                in the courts of England and Wales, as applicable.
            </p>

            <h2>13. Contact Information</h2>
            <p>If you have any questions or concerns about these Terms, please contact us at:</p>
            <address>
                FRONTIER AI<br />
                Email: <a href="mailto:help@frontierhealth.ai">help@frontierhealth.ai</a><br />
                Address: 6th Floor One London Wall, London, United Kingdom, EC2Y 5EB
            </address>
        </div>
    );
};

export default TermsService;

