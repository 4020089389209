import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Tile from './components/Tile';
import './styles.css';
import PrivacyNotice from './components/PrivacyNotice';
import CarbonPlan from './components/CarbonPlan';
import ModernSlaveryStatement from './components/ModernSlaveryStatement';
import Footer from './components/Footer';
import TermsService from './components/TermsService';
import staticWebsite from './assets/static-website.png';

function Home() {
  return (
    <div className="App">
      {/* <Navbar /> */}
      <Tile
        backgroundImage={staticWebsite}
        // heading="AI co-workers for healthcare"
        // subheading=" Frontier Health is building a 100x output healthcare workforce that leverages the best of humans and machines to deliver unmatched speed & quality of care, at a fraction of the cost."
      />
      {/* <Statistics />
      <ContactForm />  */}
    <Footer /> 
    </div>
  );
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/privacy-notice" element={<PrivacyNotice />} />
        <Route path="/carbon-plan" element={<CarbonPlan />} />
        <Route path="/modern-slavery-statement" element={<ModernSlaveryStatement />} />
        <Route path="/terms-of-service" element={<TermsService />} />
      </Routes>
    </Router>
  );
}

export default App;
