import React from 'react';
import './Tile.css';

function Tile({ backgroundImage, heading, subheading, buttonLabel, buttonLink }) {
  return (
    <div className="tile">
      <img src={backgroundImage} className="bg-image" alt="" />
      {/* <div className="tile-content">
        <h1>{heading}</h1>
        <h2>{subheading}</h2>
        {buttonLabel && (
          <a href={buttonLink}>
            <button>{buttonLabel}</button>
          </a>
        )} */}
    </div>
  );
}

export default Tile;
